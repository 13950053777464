<template>
    <div class="list">
        <div class="map">
            <div id="container"></div> 
        </div>
        <div class="title">设备列表</div>
        <div class="content">
            <div v-if="loading">
                <van-loading type="spinner" vertical>加载中</van-loading>
            </div>
            <div v-else>
                <div v-if="roadList.length > 2">
                    <div class="block-line-part" v-for="(item,index) in roadList" :key="index" @click="enter(item)">
                        <div class="position-area" v-if="index % 2 == 0">{{item.roadName}}</div>
                        <div class="inner-area" v-else>
                            <div :class="{'box-info':true,'online':true,'isCar': true}">
                                <div v-if="item.roadType == 1" class="name">
                                    <img class="board-pic" src="http://47.100.124.36:20080/resources/screenShots/9498A203E3FE/183-QC202407160004-2024_07_19_14_17_20.png">
                                </div>
                                <div v-else>
                                    <div><span>天气：</span> {{item.melve && item.melve.melvesStatus ? '12':'0'}}%</div>
                                    <div><span>温度：</span>{{item.melve && item.melve.melvesStatus ? item.melve.melvesStatus.airTemp : '0.00'}}℃</div>
                                    <div><span>湿度：</span>{{item.melve && item.melve.melvesStatus ?item.melve.melvesStatus.airHumi : '0'}}%</div>
                                    <div><span>能见度：</span>{{item.melve && item.melve.melvesStatus ? item.melve.melvesStatus.visibility : '10000'}}m</div>
                                    <div class="flex-algin-center"><span>路面：</span>
                                    {{ item.melve && item.melve.melvesStatus && item.melve.melvesStatus.roadState == 1 ? 
                                    '潮湿' : item.melve && item.melve.melvesStatus && item.melve.melvesStatus.roadState == 2 ? 
                                    '积水' : item.melve && item.melve.melvesStatus && item.melve.melvesStatus.roadState == 3 ? 
                                    '积雪' : item.melve && item.melve.melvesStatus && item.melve.melvesStatus.roadState == 4 ? 
                                    '结冰' :'干燥'}}
                                    <span v-if="(item.melve && item.melve.melvesStatus && item.melve.melvesStatus.roadState == 1) || (item.melve && item.melve.melvesStatus && item.melve.melvesStatus.roadState == 2)" class="road-tag-mild"></span>
                                    <span v-if="(item.melve && item.melve.melvesStatus && item.melve.melvesStatus.roadState == 3) || (item.melve && item.melve.melvesStatus && item.melve.melvesStatus.roadState == 4)" class="road-tag-serious"></span>
                                    </div>
                                    <div v-if="item.melve && item.melve.melvesStatus &&item.melve.melvesStatus.roadState >= 2 ">
                                    <span>厚度：</span>{{item.melve.melvesStatus.coverThickness ? item.melve.melvesStatus.coverThickness : 0}} mm
                                    </div>
                                    <div class="time">{{item.melve && item.melve.melvesStatus ?item.melve.melvesStatus.recordTime : '0000-00-00 00:00:00'}}</div>
                                </div>
                                <!-- <div class="name">{{ item.id }}</div> -->
                                <!-- <div><span>设备S N：</span></div>
                                <div>{{ item.contactId }}</div>
                                <div><span>设备状态：</span>{{item.roadState === 'online' ? '在线' : '离线'}}</div>
                                <div><span>停车状态：</span>{{item.parkChange ? item.parkChange.parkChange === 1?'有车' :'无车' : '无车'}}</div>
                                <div><span>设备电量：</span> {{item.parkChange ? item.parkChange.batValue: '0'}}%</div>
                                <div class="time">
                                    {{ 
                                        item.parkChange ? 
                                        item.parkChange.uploadTime
                                        : item.updateTime || '0000-00-00 00:00:00'
                                    }}
                                </div> -->
                            </div>
                        </div>
                        <div class="line-area"></div>
                        <div class="inner-area" v-if="index % 2 == 0">
                            <div :class="{'box-info':true,'online':true,'isCar':true}">
                                <div v-if="item.roadType == 1" class="name">
                                    <img class="board-pic" src="http://47.100.124.36:20080/resources/screenShots/9498A203E3FE/183-QC202407160004-2024_07_19_14_17_20.png">
                                </div>
                                <div v-else>
                                    <div><span>天气：</span> {{item.melve && item.melve.melvesStatus ? '12':'0'}}%</div>
                                    <div><span>温度：</span>{{item.melve && item.melve.melvesStatus ? item.melve.melvesStatus.airTemp : '0.00'}}℃</div>
                                    <div><span>湿度：</span>{{item.melve && item.melve.melvesStatus ?item.melve.melvesStatus.airHumi : '0'}}%</div>
                                    <div><span>能见度：</span>{{item.melve && item.melve.melvesStatus ? item.melve.melvesStatus.visibility : '10000'}}m</div>
                                    <div class="flex-algin-center"><span>路面：</span>
                                    {{ item.melve && item.melve.melvesStatus && item.melve.melvesStatus.roadState == 1 ? 
                                    '潮湿' : item.melve && item.melve.melvesStatus && item.melve.melvesStatus.roadState == 2 ? 
                                    '积水' : item.melve && item.melve.melvesStatus && item.melve.melvesStatus.roadState == 3 ? 
                                    '积雪' : item.melve && item.melve.melvesStatus && item.melve.melvesStatus.roadState == 4 ? 
                                    '结冰' :'干燥'}}
                                    <span v-if="(item.melve && item.melve.melvesStatus && item.melve.melvesStatus.roadState == 1) || (item.melve && item.melve.melvesStatus && item.melve.melvesStatus.roadState == 2)" class="road-tag-mild"></span>
                                    <span v-if="(item.melve && item.melve.melvesStatus && item.melve.melvesStatus.roadState == 3) || (item.melve && item.melve.melvesStatus && item.melve.melvesStatus.roadState == 4)" class="road-tag-serious"></span>
                                    </div>
                                    <div v-if="item.melve && item.melve.melvesStatus &&item.melve.melvesStatus.roadState >= 2 ">
                                    <span>厚度：</span>{{item.melve && item.melve.melvesStatus && item.melve.melvesStatus.coverThickness ? item.melve.melvesStatus.coverThickness : 0}} mm
                                    </div>
                                    <div class="time">{{item.melve && item.melve.melvesStatus ?item.melve.melvesStatus.recordTime : '0000-00-00 00:00:00'}}</div>
                                </div>
                                <!-- <div><span>设备S N：</span></div>
                                <div>{{ item.contactId }}</div>
                                <div><span>设备状态：</span>{{item.roadState === 'online' ? '在线' : '离线'}}</div>
                                <div><span>停车状态：</span>{{item.parkChange ? item.parkChange.parkChange === 1?'有车' :'无车' : '无车'}}</div>
                                <div><span>设备电量：</span> {{item.parkChange ? item.parkChange.batValue: '0'}}%</div> -->
                                
                            </div>
                        </div>
                        <div class="position-area" v-else>
                            {{item.roadName}}
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div :class="{'block-inline-row':true,'online':true,'isCar': true}" 
                        v-for="(item,index) in devList" :key="index" @click="enter(item)">
                        <div class="top">
                            <div class="name">{{item.roadName}}</div>
                            <div class="name">{{item.id}}</div>
                        </div>
                        <div v-if="item.roadType == 1" class="name">
                                    <img class="board-pic" src="http://47.100.124.36:20080/resources/screenShots/9498A203E3FE/183-QC202407160004-2024_07_19_14_17_20.png">
                        </div>
                                <div v-else>
                                    <div><span>天气：</span> {{item.melve && item.melve.melvesStatus ? '12':'0'}}</div>
                                    <div><span>温度：</span>{{item.melve && item.melve.melvesStatus ? item.melve.melvesStatus.airTemp : '0.00'}}℃</div>
                                    <div><span>湿度：</span>{{item.melve && item.melve.melvesStatus ?item.melve.melvesStatus.airHumi : '0'}}%</div>
                                    <div><span>能见度：</span>{{item.melve && item.melve.melvesStatus ? item.melve.melvesStatus.visibility : '10000'}}m</div>
                                    <div class="flex-algin-center"><span>路面：</span>
                                    {{ item.melve && item.melve.melvesStatus && item.melve.melvesStatus.roadState == 1 ? 
                                    '潮湿' : item.melve && item.melve.melvesStatus && item.melve.melvesStatus.roadState == 2 ? 
                                    '积水' : item.melve && item.melve.melvesStatus && item.melve.melvesStatus.roadState == 3 ? 
                                    '积雪' : item.melve && item.melve.melvesStatus && item.melve.melvesStatus.roadState == 4 ? 
                                    '结冰' :'干燥'}}
                                    <span v-if="(item.melve && item.melve.melvesStatus && item.melve.melvesStatus.roadState == 1) || (item.melve && item.melve.melvesStatus && item.melve.melvesStatus.roadState == 2)" class="road-tag-mild"></span>
                                    <span v-if="(item.melve && item.melve.melvesStatus && item.melve.melvesStatus.roadState == 3) || (item.melve && item.melve.melvesStatus && item.melve.melvesStatus.roadState == 4)" class="road-tag-serious"></span>
                                    </div>
                                    <div v-if="item.melve && item.melve.melvesStatus &&item.melve.melvesStatus.roadState >= 2 ">
                                    <span>厚度：</span>{{item.melve && item.melve.melvesStatus && item.melve.melvesStatus.coverThickness ? item.melve.melvesStatus.coverThickness : 0}} mm
                                    </div>
                                    <div class="time">{{item.melve && item.melve.melvesStatus ?item.melve.melvesStatus.recordTime : '0000-00-00 00:00:00'}}</div>
                                </div>
                        <!-- <div class="middle">
                            <div><span>设备S N：</span>{{item.contactId}}</div>
                            <div><span>设备状态：</span>{{item.devStatus === 'online' ? '在线' : '离线'}}</div>
                            <div><span>停车状态：</span>{{item.parkChange ? item.parkChange.parkChange === 1?'有车' :'无车' : '无车'}}</div>
                            <div><span>设备电量：</span> {{item.parkChange ? item.parkChange.batValue: '0'}}%</div>
                        </div> -->
                        <div class="bottom">
                            <div class="time">
                                {{
                                    item.parkChange ? 
                                    item.parkChange.uploadTime
                                    : item.updateTime || '0000-00-00 00:00:00'
                                }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <tabBar />
    </div>
</template>

<script>
import tabBar from "@/components/rxroad/tabBar.vue";
import iconMark from '@/assets/wlms/img/marker.png'

export default {
    components: {
        tabBar,
    },
    data(){
        return{
            map:null,
            projectCode:null,
            roadList:[],
            loading:true
        }
    },
    mounted(){
        this.projectCode = window.sessionStorage.getItem("projectCode");
        this.createMap();
    },
    methods:{
        enter(item){
            window.sessionStorage.setItem('currentRoadInfo',JSON.stringify(item))
            this.$router.push({path:'/rxroad/home',query:{devCode:item.devCode}})
        },
        createMap(){
            this.map = new AMap.Map('container',{
                zooms:[4,19], //设置缩放级别范围 4-19 级
                mapStyle: 'amap://styles/darkblue', //设置地图的显示样式
                viewMode:'3D',

            });
            AMap.plugin(['AMap.ToolBar','AMap.Scale'],() => {//异步同时加载多个插件
                var toolbar = new AMap.ToolBar();
                var scale = new AMap.Scale();
                this.map.addControl(toolbar);
                this.map.addControl(scale);
            });
            this.getRoadList();
        },
        getRoadList(){
            return new Promise( () => {
                this.$api.RXROAD.getRoadInfo({
                    project:this.projectCode
                }).then( d => {
                    this.roadList = d;
                    this.loading = false;
                    this.roadList.forEach( e => {
                        const gps = [e.longitude,e.latitude]
                        if(e.longitude || e.latitude){
                            this.gpsTransformGaoDe(gps).then( d => {
                                e.GaoDelnglat = d;
                                e.longitude = d.longitude;
                                e.latitude = d.latitude;
                            })
                        }
                    })
                    
                    // 异步绘制-防止高德异步函数导致的加载失败问题
                    setTimeout(()=>{
                        this.updateMapMarker(this.roadList);
                    },1500)
                    this.getMelvesList();
                    this.getCameraList();
                    this.getBoardsList();
                })
            })
        },
        getMelvesList(){
            return new Promise( () => {
                this.$api.RXROAD.getMelveDevInfo({
                    projectCode:this.projectCode
                }).then( d => {
                    let roads = this.roadList;
                    if(d && d.length > 0){
                        //循环将气象设备放到对应的路段上
                        roads.forEach( e => {
                            d.forEach( h => {
                            if(e.id === h.devRoad){
                                e.melve = h;
                            }
                        })
                        })

                        this.roadList = roads;
                        this.$forceUpdate();
                    }
                    
                })
            })
        },
        getCameraList(){
            return new Promise( () => {
                this.$api.RXROAD.getCameraList({
                    projectCode:this.projectCode
                }).then( d => {
                    if(d && d.length > 0){
                        //循环将气象设备放到对应的路段上
                        this.roadList.forEach( e => {
                            d.forEach( h => {
                            if(e.id === h.devRoad){
                               e.camera = h;
                            }
                        })
                        })

                        //console.log(this.roadList);
                        //this.$forceUpdate();
                    }
                    
                })
            })
        },
        getBoardsList(){
        },
        // gps 转 高德坐标系
        gpsTransformGaoDe(gps){
            return new Promise( resolve => {
                new AMap.convertFrom(gps, 'gps', (status, result) => {
                    if (result.info === 'ok') {
                        resolve(result.locations[0])
                    }
                })
            })
        },
        updateMapMarker(data){
            let markArr =[];
            var devIcon = new AMap.Icon({
                size: new AMap.Size(22, 35),
                image: iconMark,
                imageSize: new AMap.Size(22, 35),
                imageOffset: new AMap.Pixel(0, 0)
            });
            for(let i=0;i<data.length;i++){
                if(data[i].longitude && data[i].latitude){
                    var marker = new AMap.Marker({
                        position: [data[i].longitude,data[i].latitude],
                        offset: new AMap.Pixel(-10, -36),
                        icon: devIcon, // 添加 Icon 图标 URL 
                        label:{
                            offset: new AMap.Pixel(0, -2),  //设置文本标注偏移量
                            content: data[i].devStatus === 'online' ? "<span class='online'>"+data[i].roadName+"</span>" : "<span class='offline'>"+data[i].roadName+"</span>",
                            direction: 'top' //设置文本标注方位
                        }
                    });
                    marker.on('click',(e) => {
                        window.sessionStorage.setItem('currentRoadInfo',JSON.stringify(data[i]))
                        this.$router.push({path:'/rxroad/home',query:{road:data[i]}})
                    });
                    markArr.push(marker);
                }
            }
            this.map.add(markArr);
            let position = [data[0].longitude, data[0].latitude]; 
            if(data[0].longitude || data[0].latitude){
                this.map.setCenter(position); 
                this.map.setZoom(12);
            }
        },
    }
}
</script>

<style lang="scss" scoped>
#container {
    width:100%; height: 18rem;
}
.list{
    text-align: left;
    .title{
        padding-left: 10px;
        border-left: 4px solid #4ee2c0;
        font-weight: bolder;
        font-size: .95rem;
        margin: 10px 20px 10px 20px;
    }
    .content{
        padding: 5px 20px;
        max-height: 46vh;
        overflow-y: auto;
        padding-bottom: 8vh;
        .inline-block{
            margin-bottom: 10px;
            .bottom-block{
                background-color: #bdefe3;
                // #a0a0a0;
                padding: 10px;
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
                font-size: .85rem;
                color: #131313;
                div{
                    margin-bottom:6px;
                }
            }
            .online-dev{
                background-color: #bdefe3;
            }
            .alarm-dev{
                background-color: #fdddda;
            }
            .info{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                padding: 8px 10px 4px 10px;
                font-size: 0.85rem;
                background: linear-gradient(#4ee2c0 0%, #26999e 85%);
                //   # linear-gradient(#cccccc 0%, #717171 85%)
                div{
                    width: 50%;
                    margin-bottom: 6px;
                }
                div:last-child{
                    text-align: right;
                }
                // div:nth-child(even){
                //     text-align: right;
                // }
                span{
                    font-weight: bolder;
                    color: #fff;
                }
            }
            .info-online{
                background: linear-gradient(#4ee2c0 0%, #26999e 85%)
            }
            .info-alarm{
                background: linear-gradient(#ffc0ba 0%, #e25b4d 85%);
            }

        }
        .block-line-part{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 175px;
            .position-area{
                width: 49%;
                font-weight: bolder;
                text-align: center;
            }
            .line-area{
                width: 2%;
                height: 175px;
                background: #dedede;
                position: relative;
                &::after{
                    position: absolute;
                    top: 48%;
                    left: -10%;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background: #34a53c;
                    content:' ';
                }
            }
            .inner-area{
                width: calc(49% - 40px);
                padding: 10px 20px;
                font-size:.85rem;
                .box-info{
                    border: 1px solid #ccc;
                    background: #a5a5a5;
                    color: #fff;
                    border-radius: 10px;
                    text-align: left;
                    box-shadow: 2px 3px 6px #c1c1c1;
                    padding: 15px 12px;
                    div{
                        padding-bottom: 4px;
                    }
                    span{
                        color: #353535;
                    }
                    .name{
                        color: #fff;
                        font-weight: bolder;
                    }
                    .time{
                        color: #353535;
                        font-size: .75rem;
                    }
                }
                .board-pic{
                    width: 100%;
                    height: 30%;
                   // object-fit: none;
                   // clip:rect(0px ,100px , 0px , 400px);
                }
            }
        }
        
    }
    .block-inline-row{
        text-align: left;
        background-color: #a5a5a5;
        border-radius: 10px;
        color: #fff;
        font-size: .85rem;
        margin-bottom: 10px;
        .top{
            display: flex;
            justify-content: space-between;
            padding: 10px;
            font-weight: bolder;
            border-bottom: 1px solid #fff;
            color: #fff;
            font-size: .95rem;
        }
        .middle{
            padding: 10px;
            span{
                color: #353535;
            }
            div{
                padding-bottom: 5px;
            }
        }
        .time{
            color: #353535;
            text-align: right;
        }
        .bottom{
            padding: 10px;
            border-top: 1px solid #fff;
        }
    }
    .online{
        background-color: #a7d07b !important;
    }
    .isCar{
        background: #26999e !important;
    }
    .road-tag-mild{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #e1e56a;
    display: block;
    margin-left: 4px;
}
.road-tag-serious{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #e06868;
    display: block;
    margin-left: 4px;
}
.flex-algin-center{
    display: flex;
    align-items: center;
}
}
</style>